import React from 'react';
import { useReactiveVar } from '@apollo/client';
import Stack from '@mui/material/Stack';
import { useGetBackOfficeUser } from '../../../hooks';
import { currencyVar } from '../../../apollo/reactive-variables';
import { CurrencyFilter } from '../../filters';

const CurrencyNavItem = () => {
  const currency = useReactiveVar(currencyVar);

  return (
    <Stack direction="row" alignItems="center">
      <CurrencyFilter
        currency={currency}
        setCurrency={currencyVar}
        sx={{ '& .MuiSelect-select': { padding: '4px 10px 4px 14px' } }}
      />
    </Stack>
  );
};

export default CurrencyNavItem;
